import './Siberian.sass';
import phone from '../../assets/img/phone.png'
import phone2 from '../../assets/img/phone2.png'
import phones from '../../assets/img/phones.png'

export default function Siberian ({screen}) {
  return (
    <section className="siberian" id="about">
      <div className="siberian__first-line">
        <div className="siberian__first-text-block">
          <p className="siberian__first-title">
            Почему <span>Siberian.pro</span>
          </p>
          {screen !== 'mobile' && (
            <p className="siberian__first-text">
              Компания обладает мощным опытом 
              и экспертизой в области заказной разработки IT-систем для оцифровки бизнес-процессов. 
              Мы уже создавали цифровые продукты автобизнеса и транспортных компаний. 
              Также работаем  со сферами: промышленность 
              и производство, ритейл, финтех, медицина, туризм.
            </p>
          )}
        </div>
        <div className="siberian__first-image-block">
          <img src={phone} alt="Phone" className="siberian__first-image"/>
          <img src={phone2} alt="Phone" className="siberian__first-image"/>
        </div>
        {screen === 'mobile' && (
          <p className="siberian__first-text mobile no-tablet">
            Компания обладает мощным опытом 
            и экспертизой в области заказной разработки IT-систем для оцифровки бизнес-процессов. 
            Мы уже создавали цифровые продукты автобизнеса и транспортных компаний. 
            Также работаем  со сферами: промышленность 
            и производство, ритейл, финтех, медицина, туризм.
          </p>
        )}
      </div>
      <div className="siberian__statistics">
        <div className="siberian__statistics-card">
          <div className="siberian__card-number-block">
            <p className="siberian__card-number">
              8
            </p>
          </div>
          <p className="siberian__card-text">
            8 лет на рынке
          </p>
        </div>
        <div className="siberian__statistics-card">
          <div className="siberian__card-number-block">
            <p className="siberian__card-number">
              140
            </p>
          </div>
          <p className="siberian__card-text">
            Более 140 реализованных проектов
          </p>
        </div>
        <div className="siberian__statistics-card">
          <div className="siberian__card-number-block">
            <p className="siberian__card-number">
              9
            </p>
          </div>
          <p className="siberian__card-text">
            Более 9 млн людей пользуются нашими приложениями и сервисами
          </p>
        </div>
        <div className="siberian__statistics-card">
          <div className="siberian__card-number-block">
            <p className="siberian__card-number">
              110
            </p>
          </div>
          <p className="siberian__card-text">
            110 опытных специалистов в команде
          </p>
        </div>
      </div>
      <div className="siberian__second-line">
        <div className="siberian__second-image-block">
          <img src={phones} alt="Phones" className="siberian__second-image"/>
        </div>
        <p className="siberian__second-text">
          Уникальный подход к разработке, основанный 
          на глубокой аналитике и постоянном контроле ключевых метрик, 
          позволяет создать первичную версию продукта в короткий срок 
          и без переплаты. Вы всегда в курсе, 
          на что идут ваши деньги и на какой стадии работа.
        </p>
      </div>
    </section>
  )
}