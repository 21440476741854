import './Kv.sass';
import car from '../../assets/img/car.png'
import squares from '../../assets/img/squares.svg'

export default function Kv () {
  return (
    <section className="kv">
      <div className="kv__back"></div>
      <div className="kv__title-block">
        <img className="kv__car-image" src={car} alt="Car"/>
        <div className="kv__title-container">
        </div>
        <h1 className="kv__title">
          Турбо ускорение для вашего бизнеса
        </h1>
      </div>
      <div className="kv__subtitle-block">
        <p className="kv__subtitle">
          Комплексное решение по цифровизации бизнес-процессов от Siberian.pro
        </p>
      </div>
      <img className="kv__back-part" alt="" src={squares}/>
    </section>
  )
}