import './Companies.sass';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import {companies} from './constants'

import '@splidejs/react-splide/css';

export default function Companies ({screen}) {

  return (
    <section className="companies">
      <h2 className="companies__title">
        Нам доверяют <span>ведущие&nbsp;компании</span>
      </h2>
      {screen !== 'mobile' ? (
        <div className="companies__carousel">
          <Splide
                options={ {
                type: 'loop',
                perPage: 3,
                autoplay: true,
                gap: '130px',
                arrows: false,
                interval: 8000,
                padding: '275px',
                focus: 'center',
                pagination: false,
                breakpoints: {
                  1300: {
                    perPage: 1,
                    padding: '275px',
                  }
                }
              } }>
                {companies.map((element) => {
                  return (
                    <SplideSlide key={element.name}>
                      <img src={element.img} className={element.class ? element.class : ''} alt={element.name}/>
                    </SplideSlide>
                  )
                })}
          </Splide>
        </div>
      ) : (
        <div className="companies__table">
          {companies.map((element) => {
            return (
              <div className="companies__table-image" key={element.name}>
                <img src={element.img} className={element.class ? element.class : ''} alt={element.name}/>
              </div>
            )
          })}
        </div>
      )}
    </section>
  )
}