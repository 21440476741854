import './Footer.sass';
import logo from '../../assets/img/logo2.svg'
import telegram from '../../assets/img/socials/telegram.svg'
import vk from '../../assets/img/socials/vk.svg'

export default function Footer ({screen}) {

  return (
    <footer className="footer">
      {screen === 'desktop' ? (
        <div className="footer__container">
          <div className="footer__column">
            <img className="footer__logo" src={logo} alt="Siberia.pro"/>
            <a className="footer__navigation-item" href="tel:+74955404271">
              +7 495 540 42 71
            </a>
            <a className="footer__navigation-item" href="mailto:sales@siberian.pro">
              sales@siberian.pro
            </a>
            <p className="footer__copywrite">
              © Siberian.pro 2023
            </p>
          </div>
          <div className="footer__column">
            <a className="footer__social-link" href="https://t.me/siberian_pro" target="_blank">
              <img src={telegram} alt="Telegram"/>
            </a>
            <a className="footer__social-link" href="https://vk.com/siberianprogroup" target="_blank">
              <img src={vk} alt="VK"/>
            </a>
          </div>
          <div className="footer__column">
            <nav className="footer__navigation">
              <div className="footer__navigation-column">
                <a className="footer__navigation-item" href="#solutions">
                  Решения для бизнеса
                </a>
                <a className="footer__navigation-item" href="#consultation">
                  Консультация
                </a>
              </div>
              <div className="footer__navigation-column">
                <a className="footer__navigation-item" href="#about">
                  О нас
                </a>
                <a className="footer__navigation-item" href="#contacts">
                  Контакты
                </a>
              </div>
            </nav>
          </div>
        </div>
      ) : (
        <div className="footer__container">
          <div className="footer__column">
            <img className="footer__logo" src={logo} alt="Siberia.pro"/>
            <a className="footer__navigation-item" href="tel:+74955404271">
              +7 495 540 42 71
            </a>
            <a className="footer__navigation-item" href="mailto:sales@siberian.pro">
              sales@siberian.pro
            </a>
            <div className="footer__column">
              <nav className="footer__navigation">
                <div className="footer__navigation-column">
                  <a className="footer__navigation-item" href="#solutions">
                    Решения для бизнеса
                  </a>
                  <a className="footer__navigation-item" href="#consultation">
                    Консультация
                  </a>
                </div>
                <div className="footer__navigation-column">
                  <a className="footer__navigation-item" href="#about">
                    О нас
                  </a>
                  <a className="footer__navigation-item" href="#contacts">
                    Контакты
                  </a>
                </div>
              </nav>
            </div>
            <div className="footer__links">
              <a className="footer__social-link" href="https://t.me/siberian_pro" target="_blank">
                <img src={telegram} alt="Telegram"/>
              </a>
              <a className="footer__social-link" href="https://vk.com/siberianprogroup" target="_blank">
                <img src={vk} alt="VK"/>
              </a>
            </div>
            <p className="footer__copywrite">
              © Siberian.pro 2023
            </p>
          </div>
        </div>
      )}
    </footer>
  )
}