import award1 from '../../assets/img/awards/1.svg'
import award2 from '../../assets/img/awards/2.svg'
import award3 from '../../assets/img/awards/3.png'
import award4 from '../../assets/img/awards/4.png'

export const awards = [
  {
    img: award3,
    awardName: 'Золотое приложение 2022',
    position: 'Серебро',
    app: 'Путешествия и транспорт, КЭМП',
  },
  {
    img: award1,
    awardName: 'Tagline Awards 2022',
    position: 'Серебро',
    app: 'Лучшее промоприложение, Клуб Друзей SPAR',
  },
  {
    img: award2,
    awardName: 'Tagline Awards 2022',
    position: 'Бронза',
    app: 'Лучшее FMCG-приложение, Клуб Друзей SPAR',
  },
  {
    img: award2,
    awardName: 'Tagline Awards 2022',
    position: 'Бронза',
    app: 'Лучший mobile/AR/VR/IoT проект, Клуб Друзей SPAR',
  },
  {
    img: award4,
    awardName: 'Рейтинг Рунета 2022',
    position: 'Бронза',
    app: 'Приложение на иностранном языке, AppForType',
  },
  {
    img: award3,
    awardName: 'Золотое приложение 2021',
    position: 'Серебро',
    app: 'Еда и напитки, Клуб Друзей SPAR',
  },
  {
    img: award2,
    awardName: 'Tagline Awards 2020-2021',
    position: 'Бронза',
    app: 'Лучший мобильный сервис, Oboz Driver',
  },
  {
    img: award2,
    awardName: 'Tagline Awards 2020-2021',
    position: 'Бронза',
    app: 'Лучшее промоприложение, Клуб Друзей SPAR',
  },
  {
    img: award2,
    awardName: 'Tagline Awards 2020-2021',
    position: 'Бронза',
    app: 'Лучшее FMCG-приложение, Клуб Друзей SPAR',
  },
] 