import './FormBlock.sass';
import Form from '../Form/Form'

export default function FormBlock ({screen, setFinishedForms, finishedForms}) {
  return (
    <section className="form-block">
      <Form 
        additionalClass="form-block__form" 
        title="Чтобы получить консультацию Максима, заполните форму ниже"
        buttonText={screen === 'mobile' ? 'Заказать звонок' : 'Заказать обратный звонок'}
        color="black"
        id="questions"
        setFinishedForms={setFinishedForms}
        finishedForms={finishedForms}/>
      <div className="form-block__background"></div>
    </section>
  )
}