import './Creator.sass';
import photo from '../../assets/img/photo.png'
import line from '../../assets/img/line.svg'

export default function Creator ({screen}) {
  return (
    <section className="creator" id="consultation">
      <div className="creator__info-block">
        <div className="creator__image-block">
          <div className="creator__line"></div>
          <div className="creator__square">
            <img className="creator__image" src={photo} alt="максим Свиридонов"/>
          </div>
        </div>
        <div className="creator__text-block">
          <p className="creator__title">
            Знакомьтесь, Максим Свиридонов
          </p>
          <p className="creator__subtitle">
            Ваш персональный эксперт по цифровизации бизнеса
          </p>
          {screen === 'desktop' && (
            <p className="creator__text desktop">
              Вероятно, вы встречались с ним на выставке Detailer Day Expo 2023.
              Максим будет лично курировать ваш проект 
              по цифровой трансформации бизнеса.
            </p>
          )}
          {screen === 'desktop' && (
            <ul className="creator__list desktop">
              <li className="creator__list-item">
                Выявить и структурировать потребности 
                ваших клиентов и партнеров
              </li>
              <li className="creator__list-item">
                Составить техническое задание 
                для дизайнеров и разработчиков
              </li>
              <li className="creator__list-item">
                Подобрать оптимальное для вас решение по срокам и стоимости
              </li>
              <li className="creator__list-item">
                Курировать весь процесс и поддерживать 
                постоянный контакт с представителем вашей компании
              </li>
            </ul>
            )}
        </div>
      </div>
      {screen !== 'desktop' && (
        <div className="creator__info-mobile">
          <p className="creator__text">
            Вероятно, вы встречались с ним на выставке Detailer Day Expo 2023.
            Максим будет лично курировать ваш проект 
            по цифровой трансформации бизнеса.
          </p>
          <ul className="creator__list">
            <li className="creator__list-item">
              Выявить и структурировать потребности 
              ваших клиентов и партнеров
            </li>
            <li className="creator__list-item">
              Составить техническое задание 
              для дизайнеров и разработчиков
            </li>
            <li className="creator__list-item">
              Подобрать оптимальное для вас решение по срокам и стоимости
            </li>
            <li className="creator__list-item">
              Курировать весь процесс и поддерживать 
              постоянный контакт с представителем вашей компании
            </li>
          </ul>
        </div>
      )}
      <div className="creator__info-line">
        {screen === 'desktop' && (
          <img className="creator__line-image desktop" alt="" src={line}/>
        )}
        <p className="creator__text creator__text_bold">
          В результате вы получите уникальное решение, тонко 
          настроенное под задачи вашего бизнеса.
        </p>
      </div>
    </section>
  )
}