import './SolutionsInfo.sass';
import circles from '../../assets/img/circles.svg'
import car from '../../assets/img/green_car.png'

export default function SolutionsInfo ({openForm, screen}) {
  return (
    <section className="solutions-info">
      <img className="solutions-info__circles" src={circles} alt=""/>
      <div className="solutions-info__car-box">
        <img className="solutions-info__car" src={car} alt="Car"/>
      </div>
      <div className="solutions-info__text-block">
        <p className="solutions-info__title">
          Всего более 30 готовых 
          и проверенных решений 
          для&nbsp;бизнеса. 
        </p>
        {screen !== 'mobile' ? (
          <p className="solutions-info__subtitle">
            Решайте сами, какие из них 
            <br/>актуальны для вашей компании.
          </p>
        ) : (
          <p className="solutions-info__subtitle">
            Решайте сами, какие
            <br/>из них актуальны 
            <br/>для вашей компании.
          </p>
        )}
        <p className="solutions-info__more-text">
          Узнать стоимость и сроки реализации проекта
        </p>
        <button type="button" className="solutions-info__button" onClick={openForm}>
          {screen !== 'mobile' ? 'Узнать стоимость и сроки реализации проекта' : 'Рассчитать'}
        </button>
      </div>
    </section>
  )
}