import './Offers.sass';

export default function Offers ({openForm, screen}) {
  return (
    <section className="offers">
      <div className="offers__container">
        <div className="offers__card">
          <p className="offers__text">
            Автоматизируйте рутинные процессы, чтобы закрывать сделки на 30% быстрее
          </p>
        </div>
        <div className="offers__card">
          <p className="offers__text">
            Предложите дилерам удобный сервис, чтобы крепко привязать их к себе
          </p>
        </div>
        <div className="offers__card">
          <p className="offers__text">
            Управляйте процессами из любой точки мира прямо
            с экрана своего смартфона
          </p>
        </div>
      </div>
      {screen !== 'mobile' ? (
        <button type="button" className="offers__button" onClick={openForm}>
          Сделайте первый шаг к цифровизации
        </button>
      ) : (
        <div className="offers__button-block mobile no-tablet">
          <p className="offers__button-block-title">
            Сделайте первый шаг к&nbsp;цифровизации
          </p>
          <button type="button" className="offers__button" onClick={openForm}>
            Вперёд!
          </button>
        </div>
      )}
    </section>
  )
}