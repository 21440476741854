import './Awards.sass';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import cup from '../../assets/img/cup.svg'
import left from '../../assets/img/left.svg'
import right from '../../assets/img/right.svg'
import {awards} from './constants'

export default function Awards () {

  return (
    <section className="awards">
      <div className="awards__line">
        <div className="awards__line-block">
          <img className="awards__image" alt="Cup" src={cup}/>
          <p className="awards__title">
            Нас высоко оценивают заказчики и эксперты индустрии
          </p>
        </div>
      </div>
      <div className="awards__carousel">
      <Splide hasTrack={false}
              options={ {
              type: 'loop',
              perPage: 3,
              autoplay: true,
              gap: '60px',
              interval: 8000,
              focus: 'center',
              pagination: false,
              breakpoints: {
                1300: {
                  gap: '20px',
                  arrows: false,
                },
                720: {
                  gap: '42px',
                  perPage: 1,
                  perMove: 1,
                  padding: {right: 172},
                  focus: 1,
                },
              }
            } }>
              <SplideTrack>
                {awards.map((element, index) => {
                  return (
                    <SplideSlide key={index}>
                      <div className="awards__card">
                        <div className="awards__logo-block">
                          <img className="awards__logo" src={element.img} alt={element.awardName}/>
                        </div>
                        <p className="awards__card-title">
                          {element.awardName}
                        </p>
                        <p className="awards__color">
                          {element.position}
                        </p>
                        <p className="awards__info">
                          {element.app}
                        </p>
                      </div>
                    </SplideSlide>
                  )
                })}
              </SplideTrack>
        <div className="splide__arrows">
          <button className="splide__arrow splide__arrow--prev"><img src={left}/></button>
          <button className="splide__arrow splide__arrow--next"><img src={right}/></button>
        </div>
      </Splide>

      </div>
    </section>
  )
}