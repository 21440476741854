import './Header.sass';
import logo from '../../assets/img/logo.svg'

export default function Header () {
  return (
    <header className="header">
      <img className="header__logo" src={logo} alt="Siberian.pro"/>
      <nav className="header__navigation">
        <ul className="header__navigation-list">
          <li className="header__navigation-item">
            <a className="header__link" href="#solutions">
              Решения для бизнеса
            </a>
          </li>
          <li className="header__navigation-item">
            <a className="header__link" href="#consultation">
              Консультация
            </a>
          </li>
          <li className="header__navigation-item">
            <a className="header__link" href="#about">
              О нас
            </a>
          </li>
          <li className="header__navigation-item">
            <a className="header__link" href="#contacts">
              Контакты
            </a>
          </li>
        </ul>
      </nav>
    </header>
  )
}