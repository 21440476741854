import './App.sass'
import { useState, useEffect } from 'react'
import Header from '../Header/Header'
import Kv from '../Kv/Kv'
import Offers from '../Offers/Offers'
import Solutions from '../Solutions/Solutions'
import SolutionsInfo from '../SolutionsInfo/SolutionsInfo'
import Benefits from '../Benefits/Benefits'
import Cases from '../Cases/Cases'
import Creator from '../Creator/Creator'
import FormBlock from '../FormBlock/FormBlock'
import Siberian from '../Siberian/Siberian'
import Companies from '../Companies/Companies'
import Awards from '../Awards/Awards'
import Ready from '../Ready/Ready'
import Footer from '../Footer/Footer'
import Form from '../Form/Form'

function App() {

  const [formVisibility, setFormVisibility] = useState(false)
  const [screen, setScreen] = useState('')
  const [finishedForms, setFinishedForms] = useState({})

  function openForm() {
    setFormVisibility(true)
  }

  function closeForm(e, closeButton = false) {
    if(e.target.classList.contains('app__popup') || closeButton) {
      setFormVisibility(false)
    }
  }

  function changeView() {
    if(window.innerWidth >= 1300) {
      setScreen('desktop');
    } else if (window.innerWidth < 1300 && window.innerWidth >= 720) {
      setScreen('tablet');
    } else if (window.innerWidth < 720) {
      setScreen('mobile')
    }
  }

  useEffect(() => {
    changeView()
    window.addEventListener('resize', () => {
      changeView()
    })
  }, [])

  useEffect(() => {
    console.log(finishedForms)
  }, [finishedForms])


  return (
    <div className="app">
      <Header />
      <main className="app__main">
        <Kv screen={screen}/>
        <Offers screen={screen} openForm={openForm}/>
        <Solutions screen={screen}/>
        <SolutionsInfo screen={screen} openForm={openForm}/>
        <Benefits screen={screen}/>
        <Cases screen={screen}/>
        <Creator screen={screen}/>
        <FormBlock screen={screen} setFinishedForms={setFinishedForms} finishedForms={finishedForms}/>
        <Siberian screen={screen}/>
        <Companies screen={screen}/>
        <Awards screen={screen}/>
        <Ready screen={screen} setFinishedForms={setFinishedForms} finishedForms={finishedForms}/>
      </main>
      <Footer screen={screen}/>
      {formVisibility && (
        <div className="app__popup" onClick={closeForm}>
          <Form 
            additionalClass={formVisibility ? 'app__form' : ''}
            title="Начните цифровизацию вашего бизнеса сейчас!"
            color="yellow"
            buttonText="Заказать обратный звонок"
            id="popup"
            setFinishedForms={setFinishedForms}
            finishedForms={finishedForms}
            closeForm={closeForm}
          />
        </div>
      )}
    </div>
  );
}

export default App;
