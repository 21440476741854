import './Benefits.sass';
import arrow from '../../assets/img/arrow.svg'
import gears from '../../assets/img/gears.svg'
import cross from '../../assets/img/cross.svg'
import speedometer from '../../assets/img/speedometer.svg'
import hearts from '../../assets/img/hearts.svg'
import crystal from '../../assets/img/crystal.svg'

export default function Benefits () {
  return (
    <section className="benefits">
      <h2 className="benefits__title">
        Выгоды от цифровизации
      </h2>
      <div className="benefits__block">
        <div className="benefits__card">
          <div className="benefits__image-block">
            <img className="benefits__image" src={gears} alt=""/>
          </div>
          <p className="benefits__text">
            Меньше ручного труда
          </p>
        </div>
        <div className="benefits__card">
          <div className="benefits__image-block">
            <img className="benefits__image" src={arrow} alt=""/>
          </div>
          <p className="benefits__text">
            Меньше расходов на оплату труда 
          </p>
        </div>
        <div className="benefits__card">
          <div className="benefits__image-block">
            <img className="benefits__image" src={cross} alt=""/>
          </div>
          <p className="benefits__text">
            Меньше задержек и&nbsp;ошибок из&#8209;за человеческого фактора
          </p>
        </div>
        <div className="benefits__card">
          <div className="benefits__image-block">
            <img className="benefits__image" src={speedometer} alt=""/>
          </div>
          <p className="benefits__text">
            Больше 
            <br/>измеримых 
            <br/>данных об эффективности сотрудников
          </p>
        </div>
        <div className="benefits__card">
          <div className="benefits__image-block">
            <img className="benefits__image" src={hearts} alt=""/>
          </div>
          <p className="benefits__text">
            Больше 
            <br/>лояльности 
            <br/>дилеров за счет удобного сервиса
          </p>
        </div>
        <div className="benefits__card">
          <div className="benefits__image-block">
            <img className="benefits__image" src={crystal} alt=""/>
          </div>
          <p className="benefits__text">
            Больше прозрачности для&nbsp;руководства и&nbsp;партнеров
          </p>
        </div>
      </div>
    </section>
  )
}