import './Form.sass';
import { useState, useEffect } from 'react';
import axios from 'axios';

export default function Form ({title, buttonText, color, additionalClass, id, setFinishedForms, finishedForms, closeForm = ''}) {

  const [formData, setFormData] = useState({phone: '', username: ''});

  function handleInputChange(e) {
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  function handleSubmit(e) {
    e.preventDefault();
    axios({
      method: 'post',
      url: 'backend/form-handler.php',
      headers: { 'content-type': 'application/json' },
      data: JSON.stringify(formData),
    })
      .then(result => {
        setFormData({phone: '', username: ''});
        setFinishedForms({...finishedForms, [id]: 'active'});
      })
      .catch(error => alert('Данные не удалось отправить'))
  };


  return (
    <div className={`form ${additionalClass}`}>
      {id === 'popup' && (
        <div class="form__close-button" onClick={(e) => closeForm(e, true)}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.28564 1.28564L10.7142 10.7142M10.7142 1.28564L1.28564 10.7142" stroke="black" strokeWidth="2" strokeLinecap="round">
            </path>
          </svg>
        </div>
      )}
      {finishedForms[id] !== 'active' && (
        <p className="form__title">
          {title}
        </p>
      )}
      {finishedForms[id] !== 'active' && (
        <form className="form__field" onSubmit={handleSubmit}>
          <input className="form__input" type="text" id="username" name="username" placeholder="Имя" required onChange={handleInputChange} value={formData.username}/>
          <input className="form__input" type="tel" id="phone"  name="phone" placeholder="Телефон" required onChange={handleInputChange} value={formData.phone}/>
          <button className={`form__submit form__submit_${color}`} type="submit">
            {buttonText}
          </button>
        </form>
      )}
      {finishedForms[id] === 'active' && (
        <p className="form__title">
          Спасибо за вашу заявку, я 
          свяжусь с вами в ближайшее время
        </p>
      )}
    </div>
  )
}