import './Cases.sass';
import case1 from "../../assets/img/cases/1.png"
import case2 from "../../assets/img/cases/2.png"
import case3 from "../../assets/img/cases/3.png"
import case4 from "../../assets/img/cases/4.png"
import case5 from "../../assets/img/cases/5.png"
import case6 from "../../assets/img/cases/6.png"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';


export default function Cases () {

  return (
    <section className="cases">
      <h3 className="cases__title">
        Мы уже помогли десяткам компаний вывести бизнес на новый уровень 
        при помощи цифровой трансформации.
      </h3>
      <p className="cases__subtitle">
        Вот лишь несколько примеров:
      </p>
      <div className="cases__carousel">
        <Splide
            options={ {
            type: 'loop',
            perPage: 2,
            autoplay: true,
            gap: '40px',
            arrows: false,
            pagination: true,
            interval: 8000,
            perMove: 1,
            breakpoints: {
              1300: {
                perPage: 1,
                padding: {right: 100},
                gap: '20px',
              },
              720: {
                pagination: false,
              },
              350: {
                gap: '10px',
              }
            }
          } }>
          <SplideSlide>
            <div className="cases__carousel-card">
              <div className="cases__card-image-block">
                <img className="cases__card-image" src={case1} alt="Fit Service"/>
              </div>
              <div className="cases__card-info">
                <div className="cases__card-info-text">
                  <p className="cases__card-title">
                    Fit Service
                  </p>
                  <p className="cases__card-text">
                    Приложение для сети автомоек Fit Service: создание заказов, 
                    программа лояльности, план доходов персонала. 
                    Автоматизировали работу, увеличили производительность и средний чек
                  </p>
                </div>
                <a className="cases__card-link" href="https://siberian.pro/case/fit-service-carwash/" target="_blank">
                  Подробнее
                </a>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="cases__carousel-card">
              <div className="cases__card-image-block">
                <img className="cases__card-image" src={case2} alt="Depo.fm"/>
              </div>
              <div className="cases__card-info">
                <div className="cases__card-info-text">
                  <p className="cases__card-title">
                    Depo.fm
                  </p>
                  <p className="cases__card-text">
                    Сайт и приложение для покупки и продажи автозапчастей 
                    по всей России. Размещение деталей, поиск по параметрам, связь 
                    с поставщиками. 2000+ заявок ежедневно
                  </p>
                </div>
                <a className="cases__card-link" href="https://siberian.pro/case/depo-fm/" target="_blank">
                  Подробнее
                </a>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="cases__carousel-card">
              <div className="cases__card-image-block">
                <img className="cases__card-image" src={case3} alt="DKC Mobile"/>
              </div>
              <div className="cases__card-info">
                <div className="cases__card-info-text">
                  <p className="cases__card-title">
                    DKC Mobile
                  </p>
                  <p className="cases__card-text">
                    Мобильный помощник для клиентов крупной производственной компании. 
                    Каталог продукции, онлайн-чат, заявки на сотрудничество, push-уведомления 
                    и не только
                  </p>
                </div>
                <a className="cases__card-link" href="https://siberian.pro/case/dkc-mobile/" target="_blank">
                  Подробнее
                </a>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="cases__carousel-card">
              <div className="cases__card-image-block">
                <img className="cases__card-image" src={case4} alt="SPAR Online"/>
              </div>
              <div className="cases__card-info">
                <div className="cases__card-info-text">
                  <p className="cases__card-title">
                    SPAR Online
                  </p>
                  <p className="cases__card-text">
                    Приложение, которое автоматизировало сборку заказов 
                    и на 40% увеличило ее точность 
                    в онлайн-магазине SPAR. Интеграция с 1С, контроль на всех 
                    этапах сборки — от сканирования и сверки товаров до оплаты
                  </p>
                </div>
                <a className="cases__card-link" href="https://siberian.pro/case/prilozhenie-dlya-sborshhikov-zakazov-spar-online/" target="_blank">
                  Подробнее
                </a>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="cases__carousel-card">
              <div className="cases__card-image-block">
                <img className="cases__card-image" src={case6} alt="Мой ДКС"/>
              </div>
              <div className="cases__card-info">
                <div className="cases__card-info-text">
                  <p className="cases__card-title">
                    Мой ДКС
                  </p>
                  <p className="cases__card-text">
                    Корпоративная социальная сеть для компании DKC с 
                    офисами в разных городах. Синхронизация всех подразделений, 
                    новости и объявления, обучение, внутренняя валюта, интеграция 
                    с Битрикс24
                  </p>
                </div>
                <a className="cases__card-link" href="https://siberian.pro/case/dkchr/" target="_blank">
                  Подробнее
                </a>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="cases__carousel-card">
              <div className="cases__card-image-block">
                <img className="cases__card-image" src={case5} alt="Oboz Driver"/>
              </div>
              <div className="cases__card-info">
                <div className="cases__card-info-text">
                  <p className="cases__card-title">
                    Oboz Driver
                  </p>
                  <p className="cases__card-text">
                    Uber в мире грузоперевозок, объединивший 11 000 
                    транспортных компаний, частных водителей 
                    и клиентов. Регистрация транспорта, поиск 
                    и прием заказов, оформление подписки
                  </p>
                </div>
                <a className="cases__card-link" href="https://siberian.pro/case/oboz-driver/" target="_blank">
                  Подробнее
                </a>
              </div>
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </section>
  )
}