import './Solutions.sass';
import arrows from '../../assets/img/arrows.svg'

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

export default function Solutions ({screen}) {
  return (
    <section className="solutions" id="solutions">
      <img className="solutions__arrows" src={arrows} alt="Arrows"/>
      <h2 className="solutions__title">
        Эти решения помогут вашему бизнесу работать еще эффективнее
      </h2>
      {screen !== 'mobile' ? (
        <div className="solutions__block">
          <div className="solutions__card">
            <p className="solutions__card-number">
              1
            </p>
            <div className="solutions__card-text-block">
              <p className="solutions__card-title">
                Личный кабинет клиента
              </p>
              <p className="solutions__card-text">
                В нем будут отображаться остатки продукции 
                на складе, актуальные цены, текущий статус контрактов, акции, спецпредложения 
                и другая информация, важная для ваших партнеров.
              </p>
            </div>
          </div>
          <div className="solutions__card">
            <p className="solutions__card-number">
              2
            </p>
            <div className="solutions__card-text-block">
              <p className="solutions__card-title">
                Функциональный каталог продукции 
              </p>
              <p className="solutions__card-text">
                Карточки товаров с подробным описанием позиций, техническими характеристиками, 
                удобными  настройками и фильтрами для быстрого поиска и возможностью сделать заказ 
                в один клик. Приложение можно интегрировать с интернет-магазином и 1С: Торговый комплекс. 
              </p>
            </div>
          </div>
          <div className="solutions__card">
            <p className="solutions__card-number">
              3
            </p>
            <div className="solutions__card-text-block">
              <p className="solutions__card-title">
                Инструменты для быстрой коммуникации
              </p>
              <p className="solutions__card-text">
                Push-уведомления для быстрого оповещения клиентов об изменении 
                статуса заказа или ваших спецпредложениях. Онлайн-чат для 
                прямого контакта с заказчиками. Система сбора и обработки 
                обратной связи поможет всегда оставаться в курсе событий. 
              </p>
            </div>
          </div>
          <div className="solutions__card">
            <p className="solutions__card-number">
              4
            </p>
            <div className="solutions__card-text-block">
              <p className="solutions__card-title">
                Автоматизация рутинных процессов
              </p>
              <p className="solutions__card-text">
                Изменение статуса контракта 
                в один клик; автоматическое создание необходимых документов; 
                оперативная коммуникация между сотрудниками. Меньше звонков, писем, сверок и ошибок 
                из-за человеческого фактора. Весь цикл заключения сделки становится быстрее и прозрачнее. 
              </p>
            </div>
          </div>
          <div className="solutions__card">
            <p className="solutions__card-number">
              5
            </p>
            <div className="solutions__card-text-block">
              <p className="solutions__card-title">
                Инструменты для маркетинга
              </p>
              <p className="solutions__card-text">
                Система сможет уведомлять ваших клиентов и партнеров 
                об акциях и специальных предложениях. А специальная программа лояльности 
                с внутренней валютой поможет связать вас долгосрочными отношениями. 
              </p>
            </div>
          </div>
          <div className="solutions__card">
            <p className="solutions__card-number">
              6
            </p>
            <div className="solutions__card-text-block">
              <p className="solutions__card-title">
                Автоматизированное обучение сотрудников
              </p>
              <p className="solutions__card-text">
                Вся справочная информация о компании, производственных цепочках 
                и методах работы прямо в приложении. Больше не нужно тратить 
                время и ресурсы, чтобы инструктировать каждого сотрудника персонально. 
                Если у персонала есть вопросы, их можно оперативно обсудить в чате. 
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="solutions__block mobile no-tablet">
          <Splide
            options={ {
            type: 'loop',
            autoplay: true,
            arrows: false,
            pagination: false,
            interval: 8000,
            perMove: 1,
            perPage: 1,
            padding: {right: 60},
            gap: '20px',
            drag: 'free',
            flickPower: 1,
          } }>
            <SplideSlide>
              <div className="solutions__card solutions__card_1">
                <div className="solutions__card-text-block">
                  <p className="solutions__card-number">
                    1
                  </p>
                  <p className="solutions__card-title">
                    Личный кабинет клиента
                  </p>
                </div>
                <p className="solutions__card-text">
                  В нем будут отображаться остатки продукции 
                  на складе, актуальные цены, текущий статус контрактов, акции, спецпредложения 
                  и другая информация, важная для ваших партнеров.
                </p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="solutions__card solutions__card_2">
                <div className="solutions__card-text-block">
                  <p className="solutions__card-number">
                    2
                  </p>
                  <p className="solutions__card-title">
                    Функциональный каталог продукции 
                  </p>
                </div>
                <p className="solutions__card-text">
                  Карточки товаров с подробным описанием позиций, техническими характеристиками, 
                  удобными  настройками и фильтрами для быстрого поиска и возможностью сделать заказ 
                  в один клик. Приложение можно интегрировать с интернет-магазином и 1С: Торговый комплекс. 
                </p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="solutions__card solutions__card_3">
                <div className="solutions__card-text-block">
                  <p className="solutions__card-number">
                    3
                  </p>
                  <p className="solutions__card-title">
                    Инструменты для быстрой коммуникации
                  </p>
                </div>
                <p className="solutions__card-text">
                  Push-уведомления для быстрого оповещения клиентов об изменении 
                  статуса заказа или ваших спецпредложениях. Онлайн-чат для 
                  прямого контакта с заказчиками. Система сбора и обработки 
                  обратной связи поможет всегда оставаться в курсе событий. 
                </p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="solutions__card solutions__card_4">
                <div className="solutions__card-text-block">
                  <p className="solutions__card-number">
                    4
                  </p>
                  <p className="solutions__card-title">
                    Автоматизация рутинных процессов
                  </p>
                </div>
                <p className="solutions__card-text">
                  Изменение статуса контракта 
                  в один клик; автоматическое создание необходимых документов; 
                  оперативная коммуникация между сотрудниками. Меньше звонков, писем, сверок и ошибок 
                  из-за человеческого фактора. Весь цикл заключения сделки становится быстрее 
                  и прозрачнее. 
                </p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="solutions__card solutions__card_5">
                <div className="solutions__card-text-block">
                  <p className="solutions__card-number">
                    5
                  </p>
                  <p className="solutions__card-title">
                    Инструменты для маркетинга
                  </p>
                </div>
                <p className="solutions__card-text">
                  Система сможет уведомлять ваших клиентов и партнеров 
                  об акциях и специальных предложениях. А специальная программа лояльности 
                  с внутренней валютой поможет связать вас долгосрочными отношениями. 
                </p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="solutions__card solutions__card_6">
                <div className="solutions__card-text-block">
                  <p className="solutions__card-number">
                    6
                  </p>
                  <p className="solutions__card-title">
                    Автоматизированное обучение сотрудников
                  </p>
                </div>
                <p className="solutions__card-text">
                  Вся справочная информация о компании, производственных цепочках 
                  и методах работы прямо в приложении. Больше не нужно тратить 
                  время и ресурсы, чтобы инструктировать каждого сотрудника персонально. 
                  Если у персонала есть вопросы, их можно оперативно обсудить в чате. 
                </p>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      )}
    </section>
  )
}