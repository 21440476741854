import sogaz from '../../assets/img/companies/sogaz.png'
import spar from '../../assets/img/companies/spar.png'
import dkc from '../../assets/img/companies/dkc.png'
import tinkoff from '../../assets/img/companies/tinkoff.png'
import sberbank from '../../assets/img/companies/sberbank.png'
import img6 from '../../assets/img/companies/6stoloto.png'
import img7 from '../../assets/img/companies/7exoatlet.png'
import img8 from '../../assets/img/companies/8skoltech.png'
import img9 from '../../assets/img/companies/9fitservice.png'
import img10 from '../../assets/img/companies/10elta.png'
import img11 from '../../assets/img/companies/11bocelwoocel.png'
import img12 from '../../assets/img/companies/12zhelezno.png'
import img13 from '../../assets/img/companies/13volma.png'
import img14 from '../../assets/img/companies/14pestovo.png'
import img15 from '../../assets/img/companies/15switips.png'
import img16 from '../../assets/img/companies/16fulldomepro.png'
import img17 from '../../assets/img/companies/17justfood.png'
import img18 from '../../assets/img/companies/18rossko.png'
import img19 from '../../assets/img/companies/19easyten.png'
import img20 from '../../assets/img/companies/20brooma.png'
import img21 from '../../assets/img/companies/21oboz.png'
import img22 from '../../assets/img/companies/22vikium.png'
import img23 from '../../assets/img/companies/23a.png'
import img24 from '../../assets/img/companies/24vsemaikiru.png'
import img25 from '../../assets/img/companies/25d.png'

export const companies = [
  {
    name: 'Согаз',
    img: sogaz,
  },
  {
    name: 'Spar',
    img: spar,
  },
  {
    name: 'DKC',
    img: dkc,
  },  
  {
    name: 'Тинькофф',
    img: tinkoff,
  },  
  {
    name: 'Сбербанк',
    img: sberbank,
  },  
  {
    name: 'Столото',
    img: img6,
  },  
  {
    name: 'Exoatlet',
    img: img7,
  },  
  {
    name: 'Skoltech',
    img: img8,
  },
  {
    name: 'FitService',
    img: img9,
  },
  {
    name: 'Элта',
    img: img10,
  },
  {
    name: 'Bocel Woocel',
    img: img11,
    class: 'sm',
  },
  {
    name: 'Железно',
    img: img12,
  },
  {
    name: 'Волма',
    img: img13,
  },
  {
    name: 'Pestovo',
    img: img14,
  },
  {
    name: 'Switips',
    img: img15,
  },
  {
    name: 'Fulldome.pro',
    img: img16,
  },
  {
    name: 'Justfood',
    img: img17,
  },
  {
    name: 'Rossko',
    img: img18,
  },
  {
    name: 'Easy Ten',
    img: img19,
  },
  {
    name: 'Brooma',
    img: img20,
  },
  {
    name: 'Oboз',
    img: img21,
  },
  {
    name: 'Викиум',
    img: img22,
  },
  {
    name: 'A',
    img: img23,
    class: 'sm',
  },
  {
    name: 'Vsemaykiru',
    img: img24,
  },
  {
    name: 'D',
    img: img25,
    class: 'extra-sm'
  },
]