import './Ready.sass';
import Form from '../Form/Form'
import question from '../../assets/img/question.svg';

export default function Awards ({screen, setFinishedForms, finishedForms}) {

  return (
    <section className="ready" id="contacts">
      <div className="ready__title-block">
        <h2 className="ready__title">
          Готовы к цифровизации бизнеса?
        </h2>
      </div>
      <div className="ready__form-block">
        <Form 
          additionalClass="ready__form"
          title="Для участников выставки специальное предложение"
          color={screen === 'mobile' ? 'black' : 'yellow'}
          buttonText={screen === 'mobile' ? 'Заказать звонок' : "Узнать подробности"}
          id="consultation"
          setFinishedForms={setFinishedForms}
          finishedForms={finishedForms}/>
        <img src={question} alt="" className="ready__image"/>
      </div>
    </section>
  )
}